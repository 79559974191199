module.exports = [{
      plugin: require('../node_modules/@outdoormap/gatsby-plugin-brand-sites/gatsby-browser.js'),
      options: {"plugins":[],"apiUrl":"https://api.naturkartan.se","apiKey":"e7220628-7f56-44e2-86cb-150cea4bd350","guideId":"545","siteUrl":"https://www.ronnearingsjon.se","lang":"sv","typesCategories":{"hiking":["33","109","116","7"],"paddling":["37"],"biking":["31","16","119"],"fishing":["26","118","105","115"],"food":["54","106"],"discover":["14"]},"typesCategoriesIncluded":{"hiking":["33","109","116","7"],"paddling":["37"],"biking":["31","16","119"],"fishing":["26","118","105","115"],"food":["54","106"],"discover":["14"]},"includeListsInTours":true,"imagePlaceholderColor":"#588157","templates":{"type":"/build/de444ddd-0502-4c3a-a1bd-45431bbedeea/src/templates/type.js","site":"/build/de444ddd-0502-4c3a-a1bd-45431bbedeea/src/templates/site.js","page":"/build/de444ddd-0502-4c3a-a1bd-45431bbedeea/src/templates/page.js","articles":"/build/de444ddd-0502-4c3a-a1bd-45431bbedeea/src/templates/articles.js","articlesPost":"/build/de444ddd-0502-4c3a-a1bd-45431bbedeea/src/templates/articles-post.js","news":"/build/de444ddd-0502-4c3a-a1bd-45431bbedeea/src/templates/news.js","newsPost":"/build/de444ddd-0502-4c3a-a1bd-45431bbedeea/src/templates/news-post.js","events":"/build/de444ddd-0502-4c3a-a1bd-45431bbedeea/src/templates/events.js","eventsPost":"/build/de444ddd-0502-4c3a-a1bd-45431bbedeea/src/templates/events-post.js","tours":"/build/de444ddd-0502-4c3a-a1bd-45431bbedeea/src/templates/tours.js","toursPost":"/build/de444ddd-0502-4c3a-a1bd-45431bbedeea/src/templates/tours-post.js","search":"/build/de444ddd-0502-4c3a-a1bd-45431bbedeea/src/templates/search.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"sv","name":"Rönne å och Ringsjön","short_name":"Rönne å och Ringsjön","description":"Vattennära upplevelser som du bär med dig länge!","theme_color":"#224d91","background_color":"#fff","icon":"static/meta-images/icon.png","start_url":"/","display":"standalone","cache_busting_mode":"none","include_favicon":false,"legacy":false,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
